<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/dashboard']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.layout.skin == 'dark' ? 'assets/images/logo/logo-dark.png' : 'assets/images/logo/logo.png' }}"
            alt="brand-logo" height="36" />

        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <div class="d-none d-xl-block top-display">
      <ul class="nav navbar-nav">
        <li class="nav-item d-none d-lg-block">
          <a type="button" class="nav-link nav-link-style btn" tooltipClass="fadeInAnimation" placement="bottom"
          ngbTooltip="Logout" (click)="logout()">
            <span [data-feather]="'power'" class="ficon font-medium-5 feather"></span>
          </a>
        </li>

    
      </ul>
    </div>
    <!--/ Bookmark -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Toggle skin -->
    <div class="user_blance">
      <b>Wallet Balance</b>- {{split || 0}} Split
    </div>
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <i class="ficon" data-feather="sun"></i>
      </a>
    </li>
    <li class="nav-item d-none d-lg-block">
      <!-- <a type="button" class="nav-link nav-link-style btn" (click)="connectWallet()">
        <i class="fa fa-wallet" style="padding: 13px 12px;"></i>
      </a> -->
      <img src="../../../../assets/images/metamask.png" class="metamask-logo" (click)="connectWallet()" style="cursor: pointer; padding: 5px;">
    </li>
    
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" [routerLink]="['/cart']">
        <i class="fa fa-shopping-cart" style="height: 20px; width: 20px;">
          <span *ngIf="cartItemCount > 0" class="badge badge-pill badge-danger badge-up" >
            {{ cartItemCount }}
          </span>
        </i>
      </a>
    </li>
    
    
    <app-navbar-notification></app-navbar-notification>
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">

        
        <ng-container *ngIf="this.currentUser && userBalance">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{currentUser | username}}</span>
            <span class="user-status">{{ranObj[userBalance.user_rank]}}</span>
          </div>
          <!-- <p class="avtar-3" *ngIf="userBalance">{{ranObj[userBalance.user_rank]}}</p> -->
          <span class="avatar"><img class="round" src="{{ currentUser.profile_picture || currentUser.avatar }}" alt="avatar" height="40"
              width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        
        <a ngbDropdownItem (click)="openProfile()">
          <span [data-feather]="'user'" [class]="'mr-50'"></span> Profile
        </a>
        <a ngbDropdownItem [routerLink]="['dashboard','withdraw']" *ngIf="userBalance">
          {{split || 0}} SPLIT
        </a>
        
        <a ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span>{{'dashboard.dashlogout' | translate}} 
        </a>
      </div>
    </li>
  </ul>
</div>


