import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
//import { AuthService } from './auth.service';
//import { UserService } from 'app/auth/service';
import { AuthService } from 'app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  apiUrl: string;
  levelData: any = [];
  defaultBitpoolStats = {
    bitpool_purchase: 0, direct_bonus: 0, indirect_bonus: 0,
    total_current_investment: 0, total_btk: 0, referral_btk: 0, purchase_per: 0, referral_per: 0
  }

  defaultbal: any = {
    BTOK: 0, TICK: 0, investment: 0, total_bitpool: 0, auto_rebuy: false,
    today_withdrawal: 0, total_deposit: 0, tick_invested: 0, total_tick_won: 0,
    total_btok_won: 0, achievement_level: 0, game_played: 0, ticket_in_play: 0
  };
  currentStartData: any = { stars: 0, tick: 0, level: 0, nextstars: 1 };

  public updateUserBalance = new BehaviorSubject<any>(null);
  public startsData = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    this.apiUrl = environment.apiUrl;
    this.updateUserBalance.subscribe(val => {
      this.updateStars(val);
    })
  }

  updateStars(val, reset = false) {
    if (val && val.unlocked_level && this.levelData && this.levelData.length) {
      let i = 0;
      this.levelData.map(item => {
        if (item.id == val.unlocked_level) {
          this.currentStartData.stars = item.id;
          this.currentStartData.tick = item.tick;
          this.currentStartData.level = item.id;
          if (i < this.levelData.length - 1) {
            this.currentStartData.nextstars = this.levelData[i + 1].tick;
          } else {
            this.currentStartData.nextstars = this.levelData[i].tick;
          }
        }
        i++;
      })
      this.startsData.next(this.currentStartData)
    } else if (reset) {
      this.currentStartData = { stars: 0, tick: 0, level: 0, nextstars: 1 };
      this.startsData.next(this.currentStartData)
    }
  }


  getCalculatedBitpoolStats(stats = null) {
    let st = (stats) ? stats : this.defaultBitpoolStats;
    // bitpool_purchase: 0, direct_bonus: 0, indirect_bonus: 0,
    // total_current_investment
    // total_btk:0, referral_btk:0, purchase_per:0, referral_per:0
    st.total_btk = st.bitpool_purchase + st.direct_bonus + st.indirect_bonus;
    st.total_btkNew = st.direct_bonus + st.indirect_bonus;
    st.referral_btk = st.direct_bonus + st.indirect_bonus;
    st.purchase_per = (st.total_btk) ? (st.bitpool_purchase * 100) / (st.total_btk) : 0;
    st.referral_per = (st.total_btk) ? (st.referral_btk * 100) / (st.total_btk) : 0;

    return st;
  }

  getlevelcriteria(): any {
    return this.http.get(`${this.apiUrl}getLevelCriteria`)
  }

  rankingDataDetails() {
    return this.http.get(`${environment.apiUrl}rankingData`)
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));
  }

  rankingDataLevel() {
    return this.http.get(`${environment.apiUrl}getAllDirectInv`)
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));
  }

  getUserNetworkData(userID): any {
    return this.http.get(`${this.apiUrl}getUserNetworkData/${userID}`)
  }

  getbitpoolUserData(userId): any {
    return this.http.get(`${this.apiUrl}bitpoolUserData/${userId}`)
  }
  unlockedLevel(input_data): any {
    return this.http.post(`${this.apiUrl}unlockedLevel`, input_data)
  }
  getSearchedNetwork(userId, searchStr) {
    return this.http.get(`${this.apiUrl}getUnilevel/${userId}/?level=2&searchStr=${searchStr}&rootUser=${userId}`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }


  getProfilePic(pic = '') {
    return (pic) ? pic : 'assets/images/profile.png';
  }

  getaffilations(user_id) {
    return this.http.get(this.apiUrl + 'getUnilevel/' + user_id).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }
  getLevelAffiliation(user_id, level) {
    return this.http.get(this.apiUrl + 'getUnilevel/' + user_id + '/?level=' + level).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  loadTrns(input_data, type?): Observable<any> {
    console.log('load:::',{ userID: input_data.user_id, page: input_data.page, recordType: input_data.type, typeUser: input_data.typeUser, searchStr:input_data.searchStr,payment_status:input_data.payment_status, pagesize:input_data.pagesize, start_date:input_data.start_date, end_date:input_data.end_date});
    
    let enc = this.auth.encode(JSON.stringify({ userID: input_data.user_id, page: input_data.page, recordType: input_data.type, typeUser: input_data.typeUser, searchStr:input_data.searchStr,payment_status:input_data.payment_status, pagesize:input_data.pagesize, start_date:input_data.start_date, end_date:input_data.end_date}));
    let url;
    if (type && type == '6') {
      // url = `${this.apiUrl}/getFriendBonusDetail?user_id=${input_data.user_id}`;
      //url = `${this.apiUrl}/history/${enc}`;
      enc = this.auth.encode(JSON.stringify(
        { user_id: input_data.user_id, page: '0', from_id:'', allInfo: false}));

      return this.getDetailFriendsBonusAccFriend(enc);

    }
    else {
      url = `${this.apiUrl}history/${enc}`;
      return this.http.get(url).pipe(map(data => {
        return this.auth.getDecode(data);
      }));
    }
    
  }
  getUserTransaction(id){
    return this.http.get(`${this.apiUrl}getUserTransaction/${id}`);
  }
  getuserDealsAgreement(id){
    return this.http.get(`${this.apiUrl}getuserDealsAgreement/${id}`);
  }

  getDetailFriendsBonusAccFriend(enc): Observable<any>  {
    return this.http.post(`${this.apiUrl}getDetailFriendsBonusAccFriend`, {enc}).pipe(map(data => {
      console.log(enc);
      return this.auth.getDecode(data);
    }));
  }
  getHomePoperties(): any {
    return this.http.get(`${this.apiUrl}homeProperty`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }
  getproperty(id = 0): any {
    return this.http.get(`${this.apiUrl}property${id ? '/' + id : ''}`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }
  manageFavProperty(id): any {
    return this.http.get(`${this.apiUrl}manageFavProperty/${id}`);
  }

  getHomeBanner(): any {
    return this.http.get(`${this.apiUrl}getBithomeBanner`);
  }
  deleteTxn(ids): any {
    return this.http.post(`${this.apiUrl}deletePayment`, ids);
  }


  getUnilevelData(enData) {
    return this.http.get(`${this.apiUrl}unilevelData?enc=${enData}`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  getUserRankHistory(userId): any {
    return this.http.get(`${this.apiUrl}getUserRankHistory/${userId}`)
  }

  loadProducts(enc) {
    return this.http.get(`${this.apiUrl}userProduct/${enc}`).pipe(map(data => {
        return this.auth.getDecode(data);
    }));
}

  uploadPDF(body): any {
    return this.http.post(`${this.apiUrl}uploadUserAgreement`, body)
  }

  getMyDeals(userId):any{
    return this.http.get(`${this.apiUrl}getuserDeals/${userId}`)
  }

}
