import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';




const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'dashboard',
    data: { title: 'Dashboard' },
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('./main/cart/cart.module').then(m => m.CartModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./main/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    data: { title: 'Users' },
    loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'transaction',
  //   data: { title: 'transaction' },
  //   loadChildren: () => import('./main/transaction/transaction.module').then(m => m.TransactionModule),
  //   canActivate: [AuthGuard]

  // },
  {
    path: 'history',
    data: { title: 'history' },
    loadChildren: () => import('./main/history/history/history.module').then(m => m.HistoryModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'referral',
    data: { title: 'Referral' },
    loadChildren: () => import('./main/referral/referral.module').then(m => m.ReferralModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'rankings-old',
    data: { title: 'Ranking' },
    loadChildren: () => import('./main/components/ranking/ranking.module').then(m => m.RankingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    data: { title: 'FAQ' },
    loadChildren: () => import('./main/setting/faq/faq.module').then(m => m.FaqModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'menus',
    data: { title: 'Menus' },
    loadChildren: () => import('./main/menus/menus.module').then(m => m.MenusModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'profile/:id',
    data: { title: 'Profile' },
    loadChildren: () => import('./main/profile/my-account.module').then(m => m.MyAccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orders',
    data: { title: 'Orders' },
    loadChildren: () => import('./main/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'two-auth',
    data: { title: 'Two-Step-Verification' },
    loadChildren: () => import('./main/two-step-verify/two-step-verify.module').then(m => m.TwoStepVerifyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addresses',
    data: { title: 'Addresses' },
    loadChildren: () => import('./main/addresses/addresses.module').then(m => m.AddressesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'portfolio',
    data: { title: 'Portfolio' },
    loadChildren: () => import('./main/portfolio/portfolio.module').then(m => m.PortfolioModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account-detail',
    data: { title: 'Account Details' },
    loadChildren: () => import('./main/acount-deatils/acount-deatils.module').then(m => m.AcountDeatilsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account-detail/:id',
    data: { title: 'Account Details' },
    loadChildren: () => import('./main/acount-deatils/acount-deatils.module').then(m => m.AcountDeatilsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'id-verification',
    data: { title: 'ID Verification' },
    loadChildren: () => import('./main/id-verification/id-verification.module').then(m => m.IdVerificationModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('./main/setting/setting.module').then(st => st.SettingModule),
  //   canActivate: [AuthGuard]

  // },
  {
    path: 'settings/:tab',
    loadChildren: () => import('./main/setting/setting.module').then(st => st.SettingModule),
    canActivate: [AuthGuard]

  },

  {
    path: '**',
    loadChildren: () => import('./main/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule),
  }
];

@NgModule({
    declarations: [
        AppComponent,
        ContextMenuComponent,
        BasicCustomContextMenuComponent,
        AnimatedCustomContextMenuComponent,
        SubMenuCustomContextMenuComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgImageFullscreenViewModule,
        FormsModule,
        ReactiveFormsModule,
        NgxFileDropModule,
        NgxDatatableModule,
        NgbModule,
        HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled'
}),
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        ShareButtonsModule.withConfig({
            debug: true,
        }),
        ShareIconsModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // provider used to create fake backend, comment while using real api
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
