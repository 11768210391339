import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User, UserProfileData } from 'app/auth/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { EncryptionService } from 'app/utils/encryption/encryption.service';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const TOKEN_KEY = 'Carental-admin-auth-token';

@Injectable({ providedIn: 'root' })
export class UserService {

  /**
   *
   * @param {HttpClient} _http
   * 
   */
  currntUserDetails: UserProfileData;
  public openWithDrawModal = new BehaviorSubject<boolean>(false)
  public profilePicUpdate = new BehaviorSubject<boolean>(false);
  public refereshProperties = new BehaviorSubject<boolean>(null);
  public openPropertyInvestModal = new BehaviorSubject<any>(null);
  public openReferralModal = new BehaviorSubject<any>(null);

  constructor(
    private _http: HttpClient,
    private _encryptionService: EncryptionService,
    private auth: AuthService,
    private router: Router,
    private encryptionService: EncryptionService


  ) {
    this.setUserData();
  }

  registerSubAdmin(data): Observable<any> {
    let enc = this._encryptionService.encode(JSON.stringify(data));
    let term = 'registerSubAdmin';
    return this._http.post(`${environment.baseApiUrl}${term}`, { enc });

  }

  resendConf(confirmationDt: any): Observable<any> {
    return this._http.post(`${environment.baseApiUrl}resendConfirmationMail`, confirmationDt)
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));
  }

  countryList(): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}countryList`);
  }

  stateList(id): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}getStates/${id}`);
  }

  register(input_data: any): Observable<any> {
    return this._http.post(environment.baseApiUrl + 'register/', input_data).pipe(map(data => {
      return this.auth.getDecode(data);
    })
    );
  }

  forgotPassword(input_data: any): Observable<any> {
    return this._http.post(environment.baseApiUrl + 'forgotPassword/', input_data)
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));

  }

  resetPassword(input_data: any, token: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: token })
    };
    return this._http.post(environment.baseApiUrl + 'resetPassword/', input_data, httpOptions)
  }

  confirmation(activationId: string) {
    return this._http.get(environment.baseApiUrl + 'emailconfirmation/' + activationId)
  }

  emailvalidate(input_data: any) {
    return this._http.post(environment.baseApiUrl + 'emailVerify/', input_data)
  }

  addManager(data, toupdate = null): Observable<any> {
    // {user_id,pack_one:amount,pack_two:amount,pack_three:amount}
    let enc = this._encryptionService.encode(JSON.stringify(data));
    let term = (toupdate) ? 'updateManager' : 'addManager';
    return this._http.post(`${environment.baseApiUrl}${term}`, { enc })
      .pipe(
        map(data => {
          return this._encryptionService.getDecode(data);
        })
      )
  }

  deleteManager(data): Observable<any> {
    // {user_id}
    let enc = this._encryptionService.encode(JSON.stringify(data));
    return this._http.post(`${environment.baseApiUrl}deleteManager`, { enc })
      .pipe(
        map(data => {
          return this._encryptionService.getDecode(data);
        })
      )
  }

  deleteAccount(input_data): any {
    return this._http.post(`${environment.baseApiUrl}deactiveAccount/${input_data.user_id}`, {});
  }

  /**
   * Get all users
   */
  getAll(): Observable<any> {
    return this._http.get<User[]>(`${environment.apiUrl}getAllUser?searchStr=`)
      .pipe(
        map(data => {
          return this._encryptionService.getDecode(data);
        })
      )
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}users/${id}`);
  }

  getUserDetails(enc) {
    return this._http.post<any>(`${environment.baseApiUrl}getUserById`, enc);
  }

  updateUserDetails(encData: any) {
    return this._http.post<any>(`${environment.baseApiUrl}updatePersonalInfo`, encData);
  }

  verfifyDocument(encData: any) {
    return this._http.post<any>(`${environment.baseApiUrl}KYCApproved`, encData);
  }

  changePassword(encData: any) {
    return this._http.post<any>(`${environment.baseApiUrl}changePassword`, encData);
  }

  gettwoFactAuthEnable(encData: any) {
    return this._http.put<any>(`${environment.baseApiUrl}twoFactAuthEnable`, encData);
  }

  twoFactAuthVerify(encData: any) {
    // {user_id,srcode} as enc
    return this._http.post<any>(`${environment.baseApiUrl}twoFactAuthVerify`, encData);
  }

  chaneAntiPhisingCode(encData: any) {
    // {user_id,srcode} as enc
    return this._http.post<any>(`${environment.baseApiUrl}chaneAntiPhisingCode`, encData);
  }

  twoFactAuthDisable(encData: any) {
    // {user_id,srcode} as enc
    return this._http.post<any>(`${environment.baseApiUrl}twoFactAuthDisable`, encData);
  }

  sponsorAll(id: any) {
    // {user_id,srcode} as enc
    return this._http.get<any>(`${environment.baseApiUrl}getSponsorList/${id}`);
  }

  uploadProfilePic(formData): Observable<any> {
    let headers = new HttpHeaders();
    //this is the important step. You need to set content type as null
    headers.append('Content-Type', undefined);
    return this._http.post(`${environment.baseApiUrl}uploadProfilePic/`, formData)

  }

  identification_file(formData): any {
    let headers = new HttpHeaders();
    headers.append('Content-Type', undefined);
    return this._http.post(`${environment.baseApiUrl}identification`, formData)
  }
  getCurrentAntiCode(enc): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}getCurrentAntiCode/${enc}`,)
  }

  changeUserSt(enc): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}activeInactiveUser/${enc}`)
  }

  setUserData(data?: any) {
    if (data) {
      this.currntUserDetails.address = (data.address) ? data.address : this.currntUserDetails.address ? this.currntUserDetails.address : '';
      this.currntUserDetails.full_name = (data.full_name) ? data.full_name : this.currntUserDetails.full_name ? this.currntUserDetails.full_name : '';
      this.currntUserDetails.id_passport = (data.id_passport) ? data.id_passport : this.currntUserDetails.id_passport ? this.currntUserDetails.id_passport : '';
      this.currntUserDetails.is_company = (data.is_company) ? data.is_company : this.currntUserDetails.is_company ? this.currntUserDetails.is_company : 0;
      this.currntUserDetails.company_name = (data.company_name) ? data.company_name : this.currntUserDetails.company_name ? this.currntUserDetails.company_name : '';
      this.currntUserDetails.avoid_retention = (data.avoid_retention) ? data.avoid_retention : this.currntUserDetails.avoid_retention ? this.currntUserDetails.avoid_retention : 0;
      this.currntUserDetails.city = (data.city) ? data.city : this.currntUserDetails.city ? this.currntUserDetails.city : '';
      this.currntUserDetails.company_id = (data.company_id) ? data.company_id : this.currntUserDetails.company_id ? this.currntUserDetails.company_id : '';
      this.currntUserDetails.country = (data.country) ? data.country : this.currntUserDetails.country ? this.currntUserDetails.country : '';
      this.currntUserDetails.date_of_birth = (data.date_of_birth) ? this.fixDateFormat(data.date_of_birth) : this.currntUserDetails.date_of_birth ? this.fixDateFormat(this.currntUserDetails.date_of_birth) : '';
      this.currntUserDetails.lang_pref = (data.lang_pref) ? data.lang_pref : this.currntUserDetails.lang_pref ? this.currntUserDetails.lang_pref : '';
      this.currntUserDetails.phone = (data.phone) ? data.phone : this.currntUserDetails.phone ? this.currntUserDetails.phone : '';
      this.currntUserDetails.postcode = (data.postcode) ? data.postcode : this.currntUserDetails.postcode ? this.currntUserDetails.postcode : '';
      this.currntUserDetails.profile_picture = (data.profile_picture) ? data.profile_picture : this.currntUserDetails.profile_picture ? this.currntUserDetails.profile_picture : '';
    } else {
      this.currntUserDetails = {
        address: '',
        avoid_retention: 0,
        city: '',
        company_id: null,
        company_name: null,
        country: '',
        date_of_birth: '',
        full_name: '',
        id_passport: '',
        is_company: 0,
        lang_pref: '',
        phone: '',
        postcode: '',
        profile_picture: '',
      }
    }
  }

  fixDateFormat(dateString: any) {
    // "1988-08-23T00:00:00.000Z"
    return dateString ? dateString.replace('T', ' ').replace('.000Z', '') : dateString;
  }

  assignPacktoUser(data): Observable<any> {
    let enc = this._encryptionService.encode(JSON.stringify(data));
    return this._http.post(`${environment.baseApiUrl}assignPack`, { enc }).pipe(map(data => {
      return this._encryptionService.getDecode(data);
    }))
  }


  getBtomaticMinAmounts() {
    return this._http.get(`${environment.baseApiUrl}getMinAmounts`)
  }

  updateBtomaticMinAmounts(input_data) {
    let enc = this._encryptionService.encode(JSON.stringify(input_data));
    return this._http.post(`${environment.baseApiUrl}updateMinAmounts`, { enc: enc })
  }


  getUserIdentity(input_data): any {
    return this._http.get(`${environment.baseApiUrl}getUserIdentity/` + input_data)
  }

  approvedIdentification(input_data): any {
    return this._http.post(`${environment.baseApiUrl}approvedIdentification`, input_data)
  }


  updateUnilevel(input_data) {
    return this._http.post(`${environment.baseApiUrl}upsertLevelPercentage`, input_data)
  }
  getfeeDetail() {
    return this._http.get(`${environment.baseApiUrl}getLevelPercentage`)
  }


  // generate xl

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    let newJson = [];
    json.forEach(el => {
      newJson.push({
        FirstName: el.first_name,
        LastName: el.last_name,
        Country: el.country_name,
        Email: el.email,
        Status: el.active == 1 ? 'Aactive' : 'In-active',
      })
    })
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newJson);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
  }
  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  logout() {
    localStorage.removeItem('token_Key');
    localStorage.removeItem('viewedBanner');
    this.router.navigate(['/']);
  }

  getUserBalance(input_data) {
    return this._http.post(environment.baseApiUrl + 'getUserBalance', input_data);
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }) );
  }

  requestSupport(formData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', undefined);
    return this._http.post(environment.baseApiUrl + 'submittickets', formData)
  }

  supportReplywithFile(input_data, id) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', undefined);
    return this._http.post(`${environment.baseApiUrl}addTicketComment`, input_data)
  }

  getAllRepliesByTicketId(id) {
    return this._http.get(environment.baseApiUrl + 'getSingleTicket/' + id)
  }

  getAllSupport(userId) {
    return this._http.get(environment.baseApiUrl + 'supportTickets?user_id=' + userId).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  downloadFile() {
    return this._http.get(environment.baseApiUrl + 'allFileList')
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));
  }

  getAllVIds() {
    return this._http.get(environment.baseApiUrl + 'getAllTutorials/')
  }

  getAllContracts(userId) {
    return this._http.get(`${environment.baseApiUrl}allContracts/${userId}`)
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));
  }

  updateWithdrawalStatus(key): any {
    return this._http.get(`${environment.baseApiUrl}updateWithdrawalStatus/${key}`);
  }
  getMinRated(): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}getMinAmounts`)
  }
  canceLTransaction(enc): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}cancelWithdrawal/${enc}`)
  }

  canceLDeposit(id): Observable<any> {
    return this._http.post(`${environment.baseApiUrl}deletePendingTrans`,{id})
  }

  canceLWithdrawals(enc): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}cancelWithdrawal/${enc}`)
  }

  getAccStatments(enc): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}getUserAccountStatement/${enc}`);
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));
  }

  getBonusInfos(data, type, boolVal, page): Observable<any> {
    let apiEnd = type == 'dailyBonus' ? 'getDetailMonthlyBonus' : type == 'AMBASSADOR' ? 'getDetailPoolBonus' : 'getDetailFriendsBonusAccFriend'
    return this._http.post(`${environment.baseApiUrl}${apiEnd}?allInfo=${boolVal}&page=${page}`, data).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  getPoppUp() {
    return this._http.get(`${environment.apiUrl}getAllPopup`)
  }

  getBonusMonth() {
    return this._http.get(`${environment.apiUrl}getVipData`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  getOverAllfunding(): Observable<any> {
    return this._http.get(`${environment.apiUrl}getUserStats`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  twoFactAuthEnable(user_id) {
    return this._http.put(`${environment.apiUrl}twoFactAuthEnable/${user_id}`, {})
  }

  SecQuestiondisable(input_data) {
    return this._http.get(`${environment.apiUrl}SecQuestiondisable/${input_data}` + '/')
  }

  questionList() {
    return this._http.get(`${environment.apiUrl}questionList/`)
  }

  questionAns(input_data) {
    return this._http.post(`${environment.apiUrl}questionAns/?lang=`, input_data)
  }

  getLoginLogs(id) {
    return this._http.get(`${environment.apiUrl}loginLogHistory/${id}`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));

  }
  uploadDoc(input_data): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', undefined);
    return this._http.post(`${environment.apiUrl}documentUpload/`, input_data)
  }

  getKycStatus(endId): Observable<any> {
    return this._http.post(`${environment.apiUrl}getUserIdentity/`, endId).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  reqForWhiteListing(data:any){
    return this._http.post(`${environment.apiUrl}whitlist`,data);
  }

  getproperty(id = 0): any {
    return this._http.get(`${environment.apiUrl}property${id ? '/' + id : ''}`);
  }

  addToCart(data:any){
    return this._http.post(`${environment.apiUrl}addCart`,data);
  }

  getCartData(){
    return this._http.get(`${environment.apiUrl}getCart`);
  }

  updateCartData(data:any){
    return this._http.post(`${environment.apiUrl}updateCart`,data);
  }

  deleteCartItem(data:any){
    return this._http.post(`${environment.apiUrl}deleteCart`,data);
  }

  payWithCard(data:any){
    return this._http.post(`${environment.apiUrl}stripe`,data);
  }

  manageFavProperty(id): any {
    return this._http.get(`${environment.apiUrl}manageFavProperty/${id}`);
  }


  bithomedownloadFile(id) {
    return this._http.get(`${environment.apiUrl}allFileList?bithome=${1}&property_id=${id}`)
  }

  stakeProperty(data): Observable<any> {
    return this._http.post(`${environment.apiUrl}propertyInvestment`, data);
  }


  userDetail(enc) {
    return this._http.get(environment.apiUrl + 'user/' + enc).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  updatePersonal(input_data) {
    return this._http.post(environment.apiUrl + 'updatePersonalInfo/', input_data).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  updatePswd(input_data) {
    return this._http.post(environment.apiUrl + 'updateProfile/', input_data)
  }

  myQuesAns(input_data) {
    return this._http.post(environment.apiUrl + 'myQuesAns/', input_data)
  }

  isLogined() {
    return this.encryptionService.decode(localStorage.getItem(TOKEN_KEY)) != null;
  }

  getRatesLocal(): Observable<any> {
    return this._http.get(`${environment.apiUrl}conversionPrices`)
  }

  getDepositAddress(data): Observable<any> {
    return this._http.post(`${environment.apiUrl}depositAmount/`, data).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }
  saveTransaction(data): Observable<any> {
    return this._http.post(`${environment.apiUrl}saveTransaction`, data);
    // .pipe(map(data => {
    //   return this.auth.getDecode(data);
    // }));
  }



  getAllAddresses(data) {
    return this._http.get(`${environment.apiUrl}getUserAddress/${data}`)
  }

  withDraw(input_data) {
    return this._http.post(`${environment.apiUrl}withdraw`, input_data).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }
  makewithDraw(withDrawDt) {
    return this._http.post(`${environment.apiUrl}withdrawConfirmation`, withDrawDt).pipe(map(data => {
      return this.auth.getDecode(data);
    }));
  }

  addAddresses(encBody, address_id = undefined) {
    let add = address_id ? 'verifyOldAddress' : 'addAddresses';
    return this._http.post(`${environment.apiUrl}${add}`, encBody)
  }

  getOtpEmail(input_data, address = false) {
    if (address)
      return this._http.post(`${environment.apiUrl}resendOTP`, { enc: input_data });
    else
      return this._http.get(`${environment.apiUrl}sendAddAddressCode/${input_data}`);
  }


  sendReferralLink(input_data) {
    return this._http.post(`${environment.apiUrl}referralSend/`, input_data)
  }


  setNotificationMarked(data): Observable<any> {
    return this._http.post(`${environment.apiUrl}notificationRead`, data)

  }

  getNotification(id): any {
    return this._http.get(`${environment.apiUrl}getNotification/${id}`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));

  }

  getRecentTxns(id): Observable<any> {
    return this._http.get(`${environment.apiUrl}recentTxns/${id}`);
  }

  chekedPropertyToken(data): Observable<any> {
    return this._http.post(`${environment.apiUrl}chekedPropertyToken`, data)

  }
  getUserInvestment(userId): Observable<any> {
    return this._http.get(`${environment.apiUrl}getUserInvestment/${userId}`,)
  }
  getUserTransactionById(transactionId): Observable<any> {
    return this._http.get(`${environment.apiUrl}getPaymentByTransaction/${transactionId}`,)
  }
  getUserReturns(userId):Observable<any>{
    return this._http.get(`${environment.apiUrl}getUserReturns/${userId}`,)
  }
  withdrawalRequest(data): Observable<any> {
    return this._http.post(`${environment.apiUrl}/withdrawalRequest`, data)
  }
  getUserWithdrawal(status: string, userId: string): Observable<any> {
    return this._http.get(`${environment.apiUrl}/getUserWithdrawal?status=${status == undefined ? '':status}&userId=${userId}`);
}
  getSplitWallet(userId): Observable<any> {
    return this._http.get(`${environment.apiUrl}getUserWallet/${userId}`,)
  }

}
